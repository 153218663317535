// @ts-check
import { z } from "zod";

export const apiValidations = {
  Login: z.object({
    username: z.string(),
    password: z.string().min(5, "Must be greater than 5 characters"),
  }),

  acceptOrder: z.object({
    totalAmount: z.number().min(1, "Must be greater than 0"),
  }),

  EditCustomer: z.object({
    fullName: z.string().min(3, "Must be greater than 3 characters"),
    email: z.string().email(),
    phoneNumber: z.string().min(5, "Must be greater than 5 characters"),
  }),

  AddUser: z.object({
    fullName: z.string().min(3, "Must be greater than 3 characters"),
    username: z.string().min(3, "Must be greater than 3 characters"),
    phoneNumber: z.string().min(5, "Must be greater than 5 characters"),
    password: z.string().min(5, "Must be greater than 5 characters"),
    role: z.string().min(1, "Required"),
  }),

  EditUser: z.object({
    fullName: z.string().min(3, "Must be greater than 3 characters"),
    username: z.string().min(3, "Must be greater than 3 characters"),
    phoneNumber: z.string().min(5, "Must be greater than 5 characters"),
    role: z.string().min(1, "Required"),
  }),

  EditProfile: z.object({
    fullName: z.string().min(3, "Must be greater than 3 characters"),
    userName: z.string().min(3, "Must be greater than 3 characters"),
    phoneNumber: z.string().min(5, "Must be greater than 5 characters"),
  }),

  ChangePassword: z
    .object({
      currentPassword: z.string().min(5, "Must be greater than 5 characters"),
      newPassword: z.string().min(5, "Must be greater than 5 characters"),
      confirmPassword: z.string().min(5, "Must be greater than 5 characters"),
    })
    .refine((data) => data.newPassword === data.confirmPassword, {
      message: "Passwords do not match",
      path: ["confirmPassword"],
    }),

  CarForm: z.object({
    make: z.string().min(3, "Required"),
    model: z.string().min(3, "Required"),
    year: z
      .union([
        z.number(),
        z.string().transform((val) => (val ? parseInt(val, 10) : 0)),
      ])
      .pipe(z.number().min(1950, "Must be greater than 1950")),
    capacity: z
      .union([
        z.number(),
        z.string().transform((val) => (val ? parseInt(val, 10) : 0)),
      ])
      .pipe(z.number().min(1, "Must be greater than 0")),
    extras: z.string().min(3, "Must be greater than 3 characters"),
    overview: z.string().min(30, "Must be greater than 30 characters"),
    features: z
      .array(z.string().min(3, "Must be greater than 3 characters"))
      .min(1, "Must be greater than 0 characters"),
  }),
};
